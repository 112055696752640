import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequiredWordPressSlug, validatorRequireNumber } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    deposit: {
      type: MDinput,
      props: {
        type: 'currency',
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    coupledDepositProductNameOnWooCommerce: {
      type: MDinput,
      props: {
        maxlength: 64
      },
      rules: [{ validator: validatorRequiredWordPressSlug }]
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
